import React from 'react';
import { Link } from 'react-router-dom';
import timerankScreen from '../images/timerank-screen.png';
import ocr2dataScreen from '../images/ocr2data-screen.png';
import './Projects.css';

const Projects = () => {
  return <>
    <div className="projects-container">
      <div>
        <h2>Projects</h2>
      </div>
      <div className="projects-list">

        <div class="project-item">
          <Link to="/project/ocr2data">
            <span>
              ocr2data converter
            </span>
            <div className="project-screen">
              <img className="project-screenshot" src={ocr2dataScreen} alt="timerank screenshot" />
            </div>
          </Link>
        </div>

        <div class="project-item">
          <a rel="noreferrer" href="https://timerank.joehare.org" target="_blank">TimeRank
            <div className="project-screen">
              <img className="project-screenshot" src={timerankScreen} alt="timerank screenshot" />
            </div>
          </a>
        </div>

      </div>
    </div>
  </>;
};

export default Projects;
