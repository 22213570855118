import React from 'react';
import Highlight from 'react-highlight';
import 'highlight.js/styles/atom-one-dark.css';

const ExcelSharp = () => {
  return(
    <div class="posts">
    <div class="post post-body">
      <h1>"Excel Sharp"</h1>
      <h2>introduction</h2>
      ClosedXML is my favorite library for messing with at least basic Excel functionality (over EPPlus due to its license).<br/><br/>

      The OpenXML SDK 2.5 exists, but it is cumbersome. That's why we have libraries like ClosedXML and EPPlus.<br/><br/>

      The advantages of using the OpenXML SDK are that it is free and it is supported by Microsoft. The disadvantages are that it is a pain to use and it is not well documented.<br/><br/>

      Even with the SDK in existence, you still need to probably use ClosedXML.<br/><br/>

      <h2>install it</h2>

      grab it with nuget like this
      <Highlight language="powershell">
        {`
Install-Package ClosedXML
        `} 
      </Highlight>

      Then we can use it in Project -> Manage Nuget Packages -> Browse -> search box enter ClosedXML<br/><br/>
    

    Start using neat stuff:


    <h3>work with workbooks</h3>
      <Highlight language="csharp">
        {`
using (var workbook = new XLWorkbook())
{
  var ws = wbook.Worksheets.Add("Sheet1");
  ws.Cell("A1").Value = "42"; // answer for everything

  wbook.SaveAs("theuniverse.xlsx");
}
        `}
    </Highlight>


    <h3>working with cells</h3>
    <Highlight language="csharp">
{`
var ws = wbook.Worksheets.Add("Sheet1");
ws.Cell("A1").Value = "42"; // answer for everything

ws.Cell("A1").Style.Font.Bold = true;
ws.Cell("A1").Style.Font.FontColor = XLColor.Red;
ws.Cell("A1").Style.Font.FontSize = 16;

ws.Cell("A1").Style.Fill.BackgroundColor = XLColor.Blue;
ws.Cell("A1").Style.Fill.PatternType = XLFillPatternValues.Solid;
`}
    </Highlight>

    <h3>Set a formula up </h3>
    <Highlight language="csharp">
      {`
ws.Cell("A1").FormulaA1 = "=SUM(A2:A3)";
      `}
    </Highlight>


    <h3>A class that does the splitting of a workbook</h3>
    <Highlight language="csharp">
     {`
using Microsoft.AspNetCore.Mvc;
using Microsoft.AspNetCore.Http;
using ClosedXML.Excel;
using System.IO;

namespace BulkyWeb.Controllers
{
    [ApiController]
    [Route("api/[controller]")]
    public class ExcelController : ControllerBase
    {
        [HttpPost]
        public IActionResult UploadExcel([FromForm] IFormFile file)
        {
            if (file == null || file.Length == 0)
            {
                return BadRequest("No file uploaded");
            }

            using (var workbook = new XLWorkbook(file.OpenReadStream()))
            {
                foreach (IXLWorksheet worksheet in workbook.Worksheets)
                {
                    // Create a new CSV file for each worksheet
                    var csvFilePath = Path.Combine(Path.GetTempPath(), $"{worksheet.Name}.csv");
                    worksheet.RowsUsed()
                      .Select(row => string.Join(",", row.Cells().Select(cell => cell.Value)))
                      .ToList().ForEach(row => System.IO.File.AppendAllText(csvFilePath, row + "\\n"));
                }
            }

            return Ok("CSV files created successfully");
        }
    }
}
       `}
    </Highlight>



    <h2>Conclusion</h2>
    ClosedXML is a great library for working with Excel files in C#.<br/><br/>

      A project where I'm using this is <a taget="_blank" href="https://github.com/jhare/ExcelWeb">ExcelWeb</a>
    </div>

    </div>
  );

};

export default ExcelSharp;
