import { Router, Route, Switch } from "react-router-dom";
import history from './utils/history';

import Header from './components/Header';
import Home from './components/Home';
import Projects from './views/Projects';
import Project from './components/Project';

import ExcelSharp from './blogs/ExcelSharp';

import './App.css';

function App() {
  return (
    <Router history={history}>
      <div className="app">
        <Header />
        <div className="content">
          <div className="router-container">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/projects" exact component={Projects} />
              <Route path="/project/:project_id" component={Project} />
              <Route path="/blogs/excelsharp" component={ExcelSharp} />
            </Switch>
          </div>
      </div>

      </div>
    </Router>
  );
}

export default App;
