import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
  const projects = require('../../views/projects-data.json');

const Project = () => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const { project_id } = useParams();
  const project = projects[project_id];

  useEffect(() => {
    var iframe = document.getElementById(project.id);
    if(iframe) {
      iframe.addEventListener('load', function() {
        setIframeLoaded(true);
      });
    }
    // TODO: See if we need a cleanup function to do AbortSignal
    // handling here
  }, [project.id]);

  return <>
    <div className="project">
      <h3>{project.title}</h3>
      <div>{project.description}</div>
    { (project.type === 'huggingface')?
      <div>
      This is a <i className="fa-brands fa-python"></i> Python project
      <div>
        <a href={project.code}>
        <i className="fa-regular fa-file-code"></i>
        project on huggingface</a>
      </div>
      <div style={{marginBottom: "16px"}}>uses pytesseract, pdf2image</div>

      </div> 
      : <div><i className="fa-brands fa-js"></i> JavaScript project</div>}

        { !iframeLoaded && <div className="spinner"><i className="fa-solid fa-spinner"></i></div> }
        { (project.type === 'huggingface')? 
            (<iframe
              id={project.id}
              title={project.id}
              src={project.src}
              frameBorder="0"
              width="600"
              height="750"
            ></iframe>)
            : (<div>
            <iframe
              id={project.id}
              title={project.id}
              src={project.src}
              frameBorder="0"
              width={project.width}
              height={project.height}
            />
            </div>)
        }
    </div>
  </>;
}

export default Project;
