import React from 'react';
import { Link } from "react-router-dom";
import sunny from '../../sunny-yellow.png';

import './Header.css';
import Hamburger from '../Hamburger/Hamburger';

const Header = () => {
  return (
    <header>
        <div className="brand">
          <div className="logo">
            <img src={sunny} alt="logo" />
          </div>
          <div className="brand-words">
            <div className="my-name"><Link to="/">Joe Hare</Link></div>
            <div className="my-initials">JH</div>
            <div className="subtitle-container">
              <a rel="noreferrer" href="https://github.com/jhare" target="_blank">
                <i className="icon fab fa-github"></i>
              </a>
              <div className="subtitle"><Link to="/">fullstack developer</Link></div>
              <a rel="noreferrer" href="https://www.linkedin.com/in/joehare/" target="_blank">
                <i className="icon fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="nav">
            <div className="nav-list-item"><Link to="/">Home</Link></div>
            <div className="nav-list-item"><Link to="/projects">Projects</Link></div>
            <div className="nav-list-item" >
              <a rel="noreferrer" href="https://joehare.org/jhare-full-resume.pdf" target="_blank">
                Resum&eacute;
              </a>
            </div>
        </div>
        <Hamburger />
    </header>
  );
};
export default Header;
