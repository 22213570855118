import React from 'react';
import { Link } from 'react-router-dom';

import JoeMail from './JoeMail';
import AzureFundamentals from '../images/microsoft-certified-azure-fundamentals.png';

import Projects from '../views/Projects';

const Home = () => {
  return <div>
    <div className="posts">
      <h2>Home</h2>
      <span>
        I am a full-stack developer and arcthiect, I develop AWS and Azure applications with help from React, C#, Python, NodeJS and Java. 
        <br/><br/>
        <JoeMail /> is a good way to get ahold of me
      </span>

      <div className="post">
      I am Azure Fundamentals certified
        <a rel="noreferrer" target="_blank" href="https://www.credly.com/badges/e8edcc97-723b-4044-886c-e8c0f93d7a0a/public_url">
          <img src={AzureFundamentals} alt="Microsoft Azure Fundamentals Badge" />
        </a>
      </div>
    </div>

    <div className="posts" style={{backgroundColor: "rgb(12,51,69)", paddingBottom: "16px"}}>
      <h2>Blogs</h2>

      <div className="post">
        <div className="post-link"> 
          <i class="fas fa-circle"></i>
          <Link to="/blogs/excelsharp">
            Excel Access in C#
          </Link>
        </div>
      </div>
    </div>

    <Projects />
  </div>
}

export default Home;
