import React from 'react';
import { Link } from "react-router-dom";
import './Hamburger.css';

const Hamburger = () => {
  const onClickLink = () => {
    document.getElementById("hamburger-toggle").checked = false;
  }

  return (
    <div className="hamburger-container">
      <input className="checkbox" type="checkbox" id="hamburger-toggle" aria-label="Toggle Navigation"/>
      <label className="hamburger" htmlFor="hamburger-toggle">
        <span className="slice"></span>
        <span className="slice"></span>
        <span className="slice"></span>
      </label>
      <div className="drawer">
        <div className="nav-list">
          <div className="nav-list-item"><Link onClick={onClickLink} to="/" >Home</Link></div>
          <div className="nav-list-item"><Link onClick={onClickLink} to="/projects">Projects</Link></div>
              <div className="nav-list-item" >
                <a rel="noreferrer" href="https://joehare.org/jhare-full-resume.pdf" onClick={onClickLink} target="_blank">
                  Resum&eacute;
                </a>
              </div>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
